@import-normalize;

@font-face {
  font-family: 'SeatBcn-Regular';
  src: url('assets/fonts/SeatBcn-Regular.ttf') format('truetype'),
    url('assets/fonts/SeatBcn-Regular.eot'),
    url('assets/fonts/SeatBcn-Regular.woff') format('woff');
}

@font-face {
  font-family: 'SeatBcn-Bold';
  src: url('assets/fonts/SeatBcn-Bold.ttf') format('truetype'),
    url('assets/fonts/SeatBcn-Bold.eot'),
    url('assets/fonts/SeatBcn-Bold.woff') format('woff');
}

@font-face {
  font-family: 'SeatBcn-Medium';
  src: url('assets/fonts/SeatBcn-Medium.ttf') format('truetype'),
    url('assets/fonts/SeatBcn-Medium.eot'),
    url('assets/fonts/SeatBcn-Medium.woff') format('woff');
}

@font-face {
  font-family: 'SeatBcn-Black';
  src: url('assets/fonts/SeatBcn-Black.ttf') format('truetype'),
    url('assets/fonts/SeatBcn-Black.eot'),
    url('assets/fonts/SeatBcn-Black.woff') format('woff');
}
